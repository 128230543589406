import React from 'react';
import '../styles/SuccessPage.css';

const SuccessPage = () => {
    return (
        <div className="success-page">
            <h1>Thank You for Your Purchase!</h1>
            <p>We appreciate your business and hope you enjoy your new item.</p>
            {/* <p>You can register your product from your account page upon delivery.</p>
            <p>You will receive a confirmation email shortly.</p> */}
        </div>
    );
};

export default SuccessPage;

