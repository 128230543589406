import React from 'react';
import '../styles/SkeletonProductCard.css';

const SkeletonProductCard = () => {
  return (
    <div className="character-card skeleton">
      <div className="character-image">
        <div className="skeleton-image"></div>
      </div>
      <div className="character-info">
        <div className="skeleton-text skeleton-title"></div>
        <div className="skeleton-text skeleton-description"></div>
        <div className="skeleton-text skeleton-price"></div>
        <div className="skeleton-button"></div>
      </div>
    </div>
  );
};

export default SkeletonProductCard;