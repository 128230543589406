import React, { useContext } from 'react';
import { CartContext } from '../contexts/CartContext';
import axios from 'axios';
import '../styles/Cart.css';

const Cart = () => {
  const { cart, removeFromCart, clearCart } = useContext(CartContext);

  const handleCheckout = async () => {
    try {
      const response = await axios.post('/api/stripe/create-checkout-session', {
        items: cart.map(item => ({
          price: item.priceId,
          quantity: item.quantity
        }))
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to initiate checkout. Please try again.');
    }
  };

  if (cart.length === 0) {
    return (
      <div className="cart">
        <h2>Your Basket is Empty</h2>
      </div>
    );
  }

  return (
    <div className="cart">
      <h2>Your Basket</h2>
      <ul>
        {cart.map(item => (
          <li key={item.id}>
            <span>{item.name} (x{item.quantity})</span>
            <span>£{(item.price * item.quantity).toFixed(2)}</span>
            <button onClick={() => removeFromCart(item.id)}>Remove</button>
          </li>
        ))}
      </ul>
      <button className="clear-button" onClick={clearCart}>Clear Basket</button>
      <button className="checkout-button" onClick={handleCheckout}>Proceed to Checkout</button>
    </div>
  );
};

export default Cart;
