import React, { useState } from 'react';
import '../styles/HomePage.css';

import { Link } from 'react-router-dom';
import DoubleSidedDivider from '../components/DoubleSidedDivider';

import BennyBunny from '../assets/images/benny-bunny.png';
import HedleyHedgehog from '../assets/images/hedley-hedgehog.png';
import OliverOwl from '../assets/images/oliver-owl.png';
import MapPlaceholder from '../assets/images/map-placeholder.png';
import AdventureFriends from '../assets/images/adventure-friends.png';
import ShopNowImage from '../assets/images/shop-now-image.png';

import useAnalytics from '../hooks/useAnalytics';

const HomePage = () => {
  const [currentFeatureImage, setCurrentFeatureImage] = useState(AdventureFriends);

  // Initialize analytics
  const analytics = useAnalytics('HomePage');

  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="forest-background">
          <div className="hero-content">
            <h1>Woodland Stories</h1>
            <p>Unlock Magical Stories with Woodland Friends</p>
            <div className="hero-buttons">
              <Link to="/shop" className="hero-shop-btn">Shop</Link>
              {/* <Link to="/login" className="hero-login-btn">Log In</Link> */}
            </div>
            <div className="hero-scroll-down" onClick={() => window.scrollTo({ top: window.scrollY + window.innerHeight * 0.9, behavior: 'smooth' })}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 13l5 5 5-5M7 6l5 5 5-5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works-section">
        <h2>How It Works</h2>
        <hr />
        <div className="how-it-works-content">
          <div className="step">
            <div className="step-info">
              <h3>Choose a Forest Friend</h3>
              <p>Meet our delightful characters and pick your new adventure buddy! Will you choose Cheeky Bunny who loves to play tricks, Curious Hedgehog who's always exploring, or Wise Old Owl who knows all the forest's secrets? Fun and stories await with each friend!</p>
              <Link to="/shop" className="step-btn">Shop</Link>
            </div>
            <div className="step-image-right">
              <img src={require('../assets/images/right-arrow.svg').default} alt="Right arrow" />
            </div>
          </div>
          <div className="step">
            <div className="step-image-left">
              <img src={require('../assets/images/left-arrow.svg').default} alt="Left arrow" />
            </div>
            <div className="step-info">
              <h3>Plan a Magical Journey</h3>
              <p>Select one of our special forest spots and get ready to explore with your new friend. Adventure is just around the corner!</p>
              <Link to="/map" className="step-btn">Map</Link>
            </div>
          </div>
          <div className="step">
            <div className="step-info">
              <h3>Discover Nature's Wonders</h3>
              <p>Stroll through the beautiful woods and keep an eye out for our magical storytelling tree stump. There's so much to see and hear!</p>
            </div>
            <div className="step-image-right">
              <img src={require('../assets/images/right-curly-arrow.svg').default} alt="Right arrow" />
            </div>
          </div>
          <div className="step">
            <div className="step-image-headphones">
              <img src={require('../assets/images/music-headphones.svg').default} alt="Music headphones" />
            </div>
            <div className="step-info">
              <h3>Listen to the Adventure</h3>
              <p>Place your little friend on top of the enchanted tree stump and listen closely. They'll share their own amazing story with you!</p>
            </div>
          </div>
        </div>
      </section>

      <DoubleSidedDivider magnitude={100} distance={60}></DoubleSidedDivider>

      <section className="features-section">
        <div className="features-content">
          <div className="features-image desktop-only">
            <img src={currentFeatureImage} alt="Feature" />
          </div>
          <div className="features-text">
            <div className="feature-item">
              <Link to="/map" className="mobile-feature-image">
                <img src={AdventureFriends} alt="Adventures with Friends" />
              </Link>
              <h2>Adventures with Friends</h2>
              <p>Take your friends with you to all of our locations and experience a new adventure at each place from the perspective of your little furry friend.</p>
            </div>
            <div className="feature-item">
              <Link to="/shop" className="mobile-feature-image">
                <img src={ShopNowImage} alt="Make a new furry friend" />
              </Link>
              <h2>Make a new furry friend</h2>
              <p>Visit our shop to get new characters and start a whole new adventure, whether that's flying above the forest or crawling through bushes!</p>
            </div>
            <div className="feature-item">
              <Link to="/map" className="mobile-feature-image">
                <img src={MapPlaceholder} alt="Where's your next adventure?" />
              </Link>
              <h2>Where's your next adventure?</h2>
              <p>See a map of all our locations and plan where your next adventure will begin.</p>
            </div>
          </div>
        </div>
      </section>

      <DoubleSidedDivider></DoubleSidedDivider>

      <section className="home-character-section">
        <div className="home-character-list">
          <div className="home-character">
            <img src={BennyBunny} alt="Benny the Bunny" />
            <div className="home-character-info">
              <h3>Benny the Bunny</h3>
              <p>Benny the bunny is a mischievous little character, always finding himself in trouble with the gardener. His cheeky nature leads him to sneak into gardens, nibbling veggies and hopping into places he's not supposed to be. Benny's adventures are full of small escapes, amusing encounters, and cheeky mishaps that make him a favorite for children. His playful and energetic antics make him the perfect companion for those who love a bit of fun and mischief.</p>
              <Link to="/shop" className="home-character-buy-btn">Buy Now</Link>
            </div>
          </div>
          <div className="home-character">
            <img src={HedleyHedgehog} alt="Hedley the Hedgehog" />
            <div className="home-character-info">
              <h3>Hedley the Hedgehog</h3>
              <p>Hedley the hedgehog is a curious woodland explorer, always wandering through the countryside in search of hidden treasures and trinkets. Hedley invites children to discover new and exciting places, making each trip an adventure filled with unexpected twists and turns. Hedley's soft but cautious nature helps him to explore safely, while his confidence never stops him from new journeys.</p>
              <Link to="/shop" className="home-character-buy-btn">Buy Now</Link>
            </div>
          </div>
          <div className="home-character">
            <img src={OliverOwl} alt="Oliver the Owl" />  
            <div className="home-character-info">
              <h3>Oliver the Owl</h3>
              <p>Oliver the owl is a wise and noble creature, gliding silently through the night sky as he travels from place to place. With keen sight and sharp mind, Oliver is always ready to help creatures in peril, offering wisdom and guidance when it's needed most.</p>
              <Link to="/shop" className="home-character-buy-btn">Buy Now</Link>
            </div>
          </div>
        </div>
      </section>

      <DoubleSidedDivider></DoubleSidedDivider>

      <section className="map-location-section">
        <h2>Discover our Locations</h2>
        <div className="map-location-content">
          <div className="map-image-container">
            <img src={MapPlaceholder} alt="Map" />
            <Link to="/map" className="visit-map-btn">Visit Map</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
