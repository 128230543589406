import React, { useState, useContext } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/AdminEmailDrafter.css';

const EmailDrafter = () => {
    const { auth } = useContext(AuthContext);
    const [subject, setSubject] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleSendEmail = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/email-marketing/send-bulk-email', { subject, htmlContent }, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setStatusMessage('Email sent successfully!');
            setSubject('');
            setHtmlContent('');
        } catch (error) {
            console.error('Error sending email:', error);
            setStatusMessage('Failed to send email.');
        }
    };

    return (
        <div className="admin-email-drafter">
            <h3>Email Drafter</h3>
            {statusMessage && <p className="status-message">{statusMessage}</p>}
            <form onSubmit={handleSendEmail} className="email-drafter-form">
                <div className="form-group">
                    <label htmlFor="emailSubject">Subject:</label>
                    <input
                        type="text"
                        id="emailSubject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        placeholder="Enter email subject"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="emailContent">Content:</label>
                    <ReactQuill
                        theme="snow"
                        value={htmlContent}
                        onChange={setHtmlContent}
                        modules={EmailDrafter.modules}
                        formats={EmailDrafter.formats}
                        placeholder="Compose your email here..."
                    />
                </div>
                <button type="submit" className="send-email-button">Send Email to All Subscribers</button>
            </form>
        </div>
    );
};

EmailDrafter.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['clean'],
        ['link', 'image', 'video']
    ],
};

EmailDrafter.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];

export default EmailDrafter;