import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/Users.css';

const Users = () => {
    const { auth } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/api/users', {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setMessage('Failed to fetch users.');
        }
    };

    const handlePromote = async (id) => {
        try {
            await axios.put(`/api/users/${id}/promote`, {}, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage('User promoted to admin successfully.');
            fetchUsers();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to promote user'}`);
        }
    };

    const handleDemote = async (id) => {
        try {
            await axios.put(`/api/users/${id}/demote`, {}, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage('User demoted to regular role successfully.');
            fetchUsers();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to demote user'}`);
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this user?')) return;
        try {
            await axios.delete(`/api/users/${id}`, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage('User deleted successfully.');
            fetchUsers();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to delete user'}`);
        }
    };

    return (
        <div className="users">
            <h3>Manage Users</h3>
            {message && <p className="message">{message}</p>}
            <table className="users-table">
                <thead>
                    <tr>
                        <th>Display Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user._id}>
                            <td>{user.displayName}</td>
                            <td>{user.email}</td>
                            <td>{user.role || 'User'}</td>
                            <td>
                                {user.role !== 'admin' ? (
                                    <button onClick={() => handlePromote(user._id)}>Promote to Admin</button>
                                ) : (
                                    <button onClick={() => handleDemote(user._id)}>Demote to User</button>
                                )}
                                <button onClick={() => handleDelete(user._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Users;