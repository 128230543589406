import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import EditBaseStationModal from './EditBaseStationModal';
import '../../styles/BaseStations.css';

const BaseStations = () => {
    const { auth } = useContext(AuthContext);
    const [baseStations, setBaseStations] = useState([]);
    const [formData, setFormData] = useState({
        base_id: '',
        friendly_name: '',
        latitude: '',
        longitude: '',
    });
    const [message, setMessage] = useState('');
    const [editingStation, setEditingStation] = useState(null);

    useEffect(() => {
        fetchBaseStations();
    }, []);

    const fetchBaseStations = async () => {
        try {
            const response = await axios.get('/api/basestations', {
                headers: { Authorization: `Bearer ${auth.token}` }
            });
            setBaseStations(response.data);
        } catch (error) {
            console.error('Error fetching base stations:', error);
            setMessage('Failed to fetch base stations.');
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/basestations', formData, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage(`Base station created successfully with ID: ${response.data._id}`);
            setFormData({ base_id: '', friendly_name: '', latitude: '', longitude: '' });
            fetchBaseStations();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to create base station'}`);
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this base station?')) return;
        try {
            await axios.delete(`/api/basestations/${id}`, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage('Base station deleted successfully.');
            fetchBaseStations();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to delete base station'}`);
        }
    };

    const openEditModal = (station) => {
        setEditingStation(station);
    };

    const closeEditModal = () => {
        setEditingStation(null);
    };

    const handleUpdate = async (updatedStation) => {
        try {
            await axios.put(`/api/basestations/${updatedStation._id}`, updatedStation, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage('Base station updated successfully.');
            fetchBaseStations();
            closeEditModal();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to update base station'}`);
        }
    };

    return (
        <div className="base-stations">
            <h3>Manage Base Stations</h3>
            {message && <p className="message">{message}</p>}
            <form onSubmit={handleCreate} className="create-form">
                <h4>Create New Base Station</h4>
                <div>
                    <label htmlFor="base_id">Base ID:</label>
                    <input
                        type="text"
                        id="base_id"
                        name="base_id"
                        value={formData.base_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="friendly_name">Friendly Name:</label>
                    <input
                        type="text"
                        id="friendly_name"
                        name="friendly_name"
                        value={formData.friendly_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="latitude">Latitude:</label>
                    <input
                        type="number"
                        step="any"
                        id="latitude"
                        name="latitude"
                        value={formData.latitude}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="longitude">Longitude:</label>
                    <input
                        type="number"
                        step="any"
                        id="longitude"
                        name="longitude"
                        value={formData.longitude}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Create Base Station</button>
            </form>

            <h4>Existing Base Stations</h4>
            <table className="base-stations-table">
                <thead>
                    <tr>
                        <th>Base ID</th>
                        <th>Friendly Name</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {baseStations.map(station => (
                        <tr key={station._id}>
                            <td>{station.base_id}</td>
                            <td>{station.friendly_name}</td>
                            <td>{station.latitude}</td>
                            <td>{station.longitude}</td>
                            <td>
                                <button onClick={() => openEditModal(station)}>Edit</button>
                                <button onClick={() => handleDelete(station._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingStation && (
                <EditBaseStationModal
                    station={editingStation}
                    onClose={closeEditModal}
                    onSave={handleUpdate}
                />
            )}
        </div>
    );
};

export default BaseStations;