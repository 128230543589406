import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

// Map event handler component
const MapEventHandler = ({ onZoomChange, onDragEnd }) => {
  const map = useMapEvents({
    zoomend: () => {
      if (onZoomChange) {
        onZoomChange(map.getZoom());
      }
    },
    dragend: () => {
      if (onDragEnd) {
        const center = map.getCenter();
        onDragEnd({ lat: center.lat, lng: center.lng });
      }
    }
  });

  return null;
};

const MapComponent = ({ 
  markers, 
  center = [51.505, -0.09], 
  zoom = 13,
  onMarkerClick,
  onZoomChange,
  onDragEnd
}) => {
  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <MapEventHandler 
        onZoomChange={onZoomChange}
        onDragEnd={onDragEnd}
      />
      {markers.map((marker, index) => (
        <Marker 
          position={[marker.lat, marker.lng]} 
          key={index}
          eventHandlers={{
            click: () => {
              if (onMarkerClick) {
                onMarkerClick(marker);
              }
            }
          }}
        >
          <Popup>
            <div>
              <h3>{marker.tag}</h3>
              <a 
                href={`https://www.google.com/maps/search/?api=1&query=${marker.lat},${marker.lng}`} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                View on Google Maps
              </a>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
