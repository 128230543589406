import React, { useState } from 'react';
import BaseStations from './admin/BaseStations';
import ApiKeys from './admin/ApiKeys';
import Users from './admin/Users';
import Characters from './admin/Characters';
import Interests from './admin/Interests';
import EmailDrafter from './admin/EmailDrafter';
import AnalyticsDashboard from './admin/AnalyticsDashboard';
import '../styles/AdminPanel.css';

const AdminPanel = () => {
    const [activeTab, setActiveTab] = useState('analytics');

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'analytics':
                return <AnalyticsDashboard />;
            case 'baseStations':
                return <BaseStations />;
            case 'apiKeys':
                return <ApiKeys />;
            case 'users':
                return <Users />;
            case 'characters':
                return <Characters />;
            case 'interests':
                return <Interests />;
            case 'emailDrafter':
                return <EmailDrafter />;
            default:
                return <AnalyticsDashboard />;
        }
    };

    return (
        <div className="admin-panel">
            <h2>Admin Panel</h2>
            <div className="admin-tabs">
                <button
                    className={activeTab === 'analytics' ? 'active' : ''}
                    onClick={() => setActiveTab('analytics')}
                >
                    Analytics
                </button>
                <button
                    className={activeTab === 'baseStations' ? 'active' : ''}
                    onClick={() => setActiveTab('baseStations')}
                >
                    Base Stations
                </button>
                <button
                    className={activeTab === 'apiKeys' ? 'active' : ''}
                    onClick={() => setActiveTab('apiKeys')}
                >
                    API Keys
                </button>
                <button
                    className={activeTab === 'users' ? 'active' : ''}
                    onClick={() => setActiveTab('users')}
                >
                    Users
                </button>
                <button
                    className={activeTab === 'characters' ? 'active' : ''}
                    onClick={() => setActiveTab('characters')}
                >
                    Characters
                </button>
                <button
                    className={activeTab === 'interests' ? 'active' : ''}
                    onClick={() => setActiveTab('interests')}
                >
                    Interests
                </button>
                <button
                    className={activeTab === 'emailDrafter' ? 'active' : ''}
                    onClick={() => setActiveTab('emailDrafter')}
                >
                    Email Drafter
                </button>
            </div>
            <div className="admin-content">
                {renderActiveTab()}
            </div>
        </div>
    );
};

export default AdminPanel;
