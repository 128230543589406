import React, { useState } from 'react';
import '../../styles/EditBaseStationModal.css';

const EditBaseStationModal = ({ station, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        _id: station._id,
        base_id: station.base_id,
        friendly_name: station.friendly_name,
        latitude: station.latitude,
        longitude: station.longitude,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h4>Edit Base Station</h4>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="base_id">Base ID:</label>
                        <input
                            type="text"
                            id="base_id"
                            name="base_id"
                            value={formData.base_id}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="friendly_name">Friendly Name:</label>
                        <input
                            type="text"
                            id="friendly_name"
                            name="friendly_name"
                            value={formData.friendly_name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="latitude">Latitude:</label>
                        <input
                            type="number"
                            step="any"
                            id="latitude"
                            name="latitude"
                            value={formData.latitude}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="longitude">Longitude:</label>
                        <input
                            type="number"
                            step="any"
                            id="longitude"
                            name="longitude"
                            value={formData.longitude}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="modal-actions">
                        <button type="submit">Save</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditBaseStationModal;