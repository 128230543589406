import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MapPage from './pages/MapPage';
import ShopPage from './pages/ShopPage';
import AdminPanel from './components/AdminPanel';
import PrivateRoute from './PrivateRoute';
import LoginPage from './pages/LoginPage';
import Register from './components/Register';
import SuccessPage from './pages/SuccessPage';
import AccountDetails from './components/AccountDetails';
import WifiSetupPage from './pages/WifiSetupPage';

const AppRoutes = () => (
  <Routes>
    {/* Public Routes */}
    <Route path="/login" element={<LoginPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="/shop" element={<ShopPage />} />
    <Route path="/wifisetup" element={<WifiSetupPage />} />
    
    {/* Protected Routes */}
    <Route path="/map" element={<MapPage />} />
    <Route path="/admin" element={<PrivateRoute adminOnly={true}><AdminPanel /></PrivateRoute>} />
    <Route path="/account" element={<PrivateRoute><AccountDetails /></PrivateRoute>} />

    {/* Other Routes */}
    <Route path="/register" element={<Register />} />
    <Route path="/success" element={<SuccessPage />} />
  </Routes>
);

export default AppRoutes;
