import { useEffect } from 'react';
import { trackPageView, trackEvent, trackError, trackInteraction } from '../utils/analytics';

const useAnalytics = (pageName) => {
    useEffect(() => {
        // Track page view when component mounts
        if (pageName) {
            trackPageView(pageName);
        }
    }, [pageName]);

    return {
        trackEvent: (eventName, eventData) => trackEvent(eventName, eventData),
        trackError: (error, componentName) => trackError(error, componentName),
        trackInteraction: (interactionType, elementId, additionalData) => 
            trackInteraction(interactionType, elementId, additionalData)
    };
};

export default useAnalytics;
