import React from 'react';
import '../styles/WifiSetupPage.css';

const WifiSetupPage = () => {
    return (
        <div className="wifi-setup-page">
            <h1 className="wifi-setup-title">WIFI Setup</h1>
            <div className="wifi-setup-content">
                <p className="wifi-setup-description">
                    This page contains the instruction for connecting a Woodland Stories Stump to a WIFI connection.
                    Below are the instructions for doing this:
                </p>

                <div className="wifi-setup-steps">
                    <div className="wifi-step">
                        <span>1. Navigate to the WIFI Settings area on your device</span>
                        <div className="wifi-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
                                <path d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z"/>
                            </svg>
                        </div>
                    </div>

                    <div className="wifi-step">
                        <span>2. Connect to "Woodland_Stories_Local", it does not require a password</span>
                        <button className="wifi-network-button">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
                                <path d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z"/>
                            </svg>
                            woodland_stories_local
                        </button>
                    </div>

                    <div className="wifi-step">
                        <span>3. Once connected come back to this page and click the button below. Continue to follow the instructions to connect the Woodland Stories Stump to your WIFI.</span>
                        <a href="http://192.168.4.1" className="wifi-login-button">Login to WIFI</a>
                    </div>
                </div>

                <p className="wifi-confirmation">
                    The Woodland Tree Stump should have audibly announced that it is connected to WIFI
                </p>
            </div>
        </div>
    );
};

export default WifiSetupPage;
