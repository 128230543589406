import React from 'react';

const ProductModal = ({ product, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-image">
          <img src={product.image} alt={product.name} />
        </div>
        <div className="modal-info">
          <h2>{product.name}</h2>
          <p className="price">£{product.price.toFixed(2)}</p>
          <p>{product.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
