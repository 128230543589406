import React, { useState, useContext } from 'react';
import { CartContext } from '../contexts/CartContext';

const ProductCard = ({ product, handleBuy, onCardClick }) => {
  const [selectedPriceId, setSelectedPriceId] = useState(product.priceId);
  const { addToCart } = useContext(CartContext);

  const handlePriceChange = (e) => {
    setSelectedPriceId(e.target.value);
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();
    addToCart({ ...product, priceId: selectedPriceId });
  };

  const isOutOfStock = !product.active;

  return (
    <div className="character-card" onClick={onCardClick}>
      <div className="character-image">
        {product.image ? (
          <img src={product.image} alt={product.name} className="product-image" />
        ) : (
          <div className="placeholder-image"></div>
        )}
      </div>
      <div className="character-info">
        <h3>{product.name}</h3>
        <p className="description">{product.description}</p>
        <div className="price-selection">
          {product.prices && product.prices.length > 1 ? (
            <select onChange={handlePriceChange} value={selectedPriceId} disabled={isOutOfStock}>
              {product.prices.map((price) => (
                <option key={price.id} value={price.id}>
                  £{(price.amount / 100).toFixed(2)}
                </option>
              ))}
            </select>
          ) : (
            <p className="price">£{product.price !== null ? product.price.toFixed(2) : 'N/A'}</p>
          )}
        </div>
        {/* Conditionally render buttons based on stock status */}
        {isOutOfStock ? (
          <div className="product-buttons">
            <button disabled className="out-of-stock">Out of Stock</button>
            <button disabled className="out-of-stock">Out of Stock</button>
          </div>
        ) : (
          <div className="product-buttons">
            <button onClick={(e) => { e.stopPropagation(); handleBuy(selectedPriceId); }}>Buy Now</button>
            <button onClick={handleAddToCart}>Add to Basket</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;