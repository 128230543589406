import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    DoughnutController
} from 'chart.js';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/AdminAnalytics.css';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    DoughnutController
);

const formatDuration = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return hours > 0 
        ? `${hours}h ${minutes % 60}m`
        : `${minutes}m ${seconds % 60}s`;
};

const AnalyticsDashboard = () => {
    const { auth } = useContext(AuthContext);
    const [analyticsData, setAnalyticsData] = useState(null);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        endDate: new Date().toISOString().split('T')[0]
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchAnalytics();
    }, [dateRange]);

    const fetchAnalytics = async () => {
        try {
            setLoading(true);
            const [summaryResponse, detailsResponse] = await Promise.all([
                axios.get('/api/analytics/summary', {
                    params: dateRange,
                    headers: { Authorization: `Bearer ${auth.token}` }
                }),
                axios.get('/api/analytics/data', {
                    params: {
                        ...dateRange,
                        limit: 1000
                    },
                    headers: { Authorization: `Bearer ${auth.token}` }
                })
            ]);

            setAnalyticsData({
                summary: summaryResponse.data.data,
                details: detailsResponse.data.data
            });
        } catch (err) {
            setError('Failed to fetch analytics data');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const preparePageViewsData = () => {
        if (!analyticsData?.details) return null;

        const pageViews = analyticsData.details
            .filter(event => event.type === 'pageview')
            .reduce((acc, event) => {
                const date = new Date(event.timestamp).toLocaleDateString();
                acc[date] = (acc[date] || 0) + 1;
                return acc;
            }, {});

        return {
            labels: Object.keys(pageViews),
            datasets: [{
                label: 'Page Views',
                data: Object.values(pageViews),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }]
        };
    };

    const prepareDeviceCategoryData = () => {
        if (!analyticsData?.summary?.deviceStats) return null;

        return {
            labels: analyticsData.summary.deviceStats.map(stat => stat._id),
            datasets: [{
                data: analyticsData.summary.deviceStats.map(stat => stat.count),
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 206, 86, 0.6)'
                ]
            }]
        };
    };

    const prepareTrafficSourcesData = () => {
        if (!analyticsData?.summary?.trafficSources) return null;

        return {
            labels: analyticsData.summary.trafficSources.map(source => source._id),
            datasets: [{
                data: analyticsData.summary.trafficSources.map(source => source.count),
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)'
                ]
            }]
        };
    };

    const prepareLocationData = () => {
        if (!analyticsData?.summary?.locationStats) return null;

        return {
            labels: analyticsData.summary.locationStats.map(stat => stat._id),
            datasets: [{
                label: 'Visitors by Country',
                data: analyticsData.summary.locationStats.map(stat => stat.count),
                backgroundColor: 'rgba(75, 192, 192, 0.6)'
            }]
        };
    };

    const prepareTopPagesData = () => {
        if (!analyticsData?.summary?.topPages) return null;

        return {
            labels: analyticsData.summary.topPages.map(page => page._id),
            datasets: [
                {
                    label: 'Total Views',
                    data: analyticsData.summary.topPages.map(page => page.totalViews),
                    backgroundColor: 'rgba(54, 162, 235, 0.6)'
                },
                {
                    label: 'Unique Views',
                    data: analyticsData.summary.topPages.map(page => page.uniqueViews),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)'
                },
                {
                    label: 'Exit Rate (%)',
                    data: analyticsData.summary.topPages.map(page => page.exitRate),
                    backgroundColor: 'rgba(255, 99, 132, 0.6)'
                }
            ]
        };
    };

    if (loading) return <div>Loading analytics...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="analytics-dashboard">
            <div className="analytics-header">
                <h2>Analytics Dashboard</h2>
                <div className="date-filters">
                    <input
                        type="date"
                        value={dateRange.startDate}
                        onChange={(e) => setDateRange(prev => ({
                            ...prev,
                            startDate: e.target.value
                        }))}
                    />
                    <input
                        type="date"
                        value={dateRange.endDate}
                        onChange={(e) => setDateRange(prev => ({
                            ...prev,
                            endDate: e.target.value
                        }))}
                    />
                </div>
            </div>

            <div className="analytics-summary">
                <div className="summary-card">
                    <h3>Total Page Views</h3>
                    <p>{analyticsData?.summary?.pageViews || 0}</p>
                </div>
                <div className="summary-card">
                    <h3>Unique Visitors</h3>
                    <p>{analyticsData?.summary?.uniqueVisitors || 0}</p>
                </div>
                <div className="summary-card">
                    <h3>Bounce Rate</h3>
                    <p>{(analyticsData?.summary?.bounceRate || 0).toFixed(2)}%</p>
                </div>
                <div className="summary-card">
                    <h3>Avg. Session Duration</h3>
                    <p>{formatDuration(analyticsData?.summary?.avgSessionDuration || 0)}</p>
                </div>
                <div className="summary-card">
                    <h3>Pages/Session</h3>
                    <p>{(analyticsData?.summary?.pagesPerSession || 0).toFixed(2)}</p>
                </div>
            </div>

            <div className="analytics-charts">
                <div className="chart-container">
                    <h3>Page Views Over Time</h3>
                    {preparePageViewsData() && (
                        <Line 
                            data={preparePageViewsData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Daily Page Views'
                                    }
                                }
                            }}
                        />
                    )}
                </div>

                <div className="chart-container">
                    <h3>Device Categories</h3>
                    {prepareDeviceCategoryData() && (
                        <Doughnut 
                            data={prepareDeviceCategoryData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    }
                                }
                            }}
                        />
                    )}
                </div>

                <div className="chart-container">
                    <h3>Traffic Sources</h3>
                    {prepareTrafficSourcesData() && (
                        <Pie 
                            data={prepareTrafficSourcesData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    }
                                }
                            }}
                        />
                    )}
                </div>

                <div className="chart-container">
                    <h3>Top Pages</h3>
                    {prepareTopPagesData() && (
                        <Bar 
                            data={prepareTopPagesData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Most Viewed Pages'
                                    }
                                },
                                scales: {
                                    x: {
                                        ticks: {
                                            maxRotation: 45,
                                            minRotation: 45
                                        }
                                    },
                                    y: {
                                        beginAtZero: true
                                    }
                                }
                            }}
                        />
                    )}
                </div>

                <div className="chart-container">
                    <h3>Visitors by Location</h3>
                    {prepareLocationData() && (
                        <Bar 
                            data={prepareLocationData()}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    }
                                },
                                scales: {
                                    y: {
                                        beginAtZero: true
                                    }
                                }
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="analytics-details">
                <h3>Recent Events</h3>
                <div className="events-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Type</th>
                                <th>Page</th>
                                <th>Device</th>
                                <th>Source</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyticsData?.details?.slice(0, 10).map((event, index) => (
                                <tr key={index}>
                                    <td>{new Date(event.timestamp).toLocaleString()}</td>
                                    <td>{event.type}</td>
                                    <td>{event.page || event.url}</td>
                                    <td>{event.deviceCategory}</td>
                                    <td>{event.trafficSource}</td>
                                    <td>
                                        {event.error ? 'Error: ' + event.error :
                                         event.event ? 'Event: ' + event.event :
                                         event.interaction ? 'Interaction: ' + event.interaction :
                                         'Page View'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsDashboard;
