import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

const AccountDetails = () => {
  const { auth } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [visits, setVisits] = useState([]);

  useEffect(() => {
    if (auth.token) {
      fetchUserDetails();
      fetchUserCharacters();
    }
  }, [auth.token]);

  const fetchUserDetails = async () => {
    try {
      const res = await axios.get('/api/users/me', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setUser(res.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchUserCharacters = async () => {
    try {
      const res = await axios.get('/api/characters', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCharacters(res.data);
    } catch (error) {
      console.error('Error fetching user characters:', error);
    }
  };

  const fetchCharacterVisits = async (characterId) => {
    try {
      const res = await axios.get(`/api/characters/${characterId}/visits`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setVisits(res.data);
    } catch (error) {
      console.error('Error fetching character visits:', error);
    }
  };

  const handleCharacterSelect = (character) => {
    setSelectedCharacter(character);
    fetchCharacterVisits(character._id);
  };

  if (!user) return <div>Loading...</div>;

  return (
    <div>
      <h2>Account Details</h2>
      <p>Display Name: {user.displayName}</p>
      <p>Email: {user.email}</p>

      <h3>Your Characters</h3>
      <ul>
        {characters.map((character) => (
          <li key={character._id}>
            <button onClick={() => handleCharacterSelect(character)}>
              {character.name}
            </button>
          </li>
        ))}
      </ul>

      {selectedCharacter && (
        <div>
          <h4>{selectedCharacter.name}'s Visit History</h4>
          <ul>
            {visits.map((visit, index) => (
              <li key={index}>
                Visited {visit.baseStation.name} on {new Date(visit.visitDate).toLocaleString()}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountDetails;