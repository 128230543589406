import React from 'react';
import Login from '../components/Login';
import '../styles/Login.css';
import '../styles/LoginPage.css';
const LoginPage = () => {
  return (
    <div className="login-page">
      <Login />
    </div>
  );
};

export default LoginPage;
