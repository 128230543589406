import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/ApiKeys.css';

const ApiKeys = () => {
    const { auth } = useContext(AuthContext);
    const [apiKeys, setApiKeys] = useState([]);
    const [baseStations, setBaseStations] = useState([]);
    const [selectedBaseStation, setSelectedBaseStation] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchApiKeys();
        fetchBaseStations();
    }, []);

    const fetchApiKeys = async () => {
        try {
            const response = await axios.get('/api/apikeys', {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setApiKeys(response.data);
        } catch (error) {
            console.error('Error fetching API keys:', error);
            setMessage('Failed to fetch API keys.');
        }
    };

    const fetchBaseStations = async () => {
        try {
            const response = await axios.get('/api/basestations', {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setBaseStations(response.data);
        } catch (error) {
            console.error('Error fetching base stations:', error);
        }
    };

    const handleGenerate = async () => {
        if (!selectedBaseStation) {
            setMessage('Please select a base station.');
            return;
        }
        try {
            const response = await axios.post('/api/apikeys/generate', {
                baseStationId: selectedBaseStation
            }, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage(`API Key generated: ${response.data.apiKey}`);
            fetchApiKeys();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to generate API key'}`);
        }
    };

    const handleRevoke = async (id) => {
        if (!window.confirm('Are you sure you want to revoke this API key?')) return;
        try {
            await axios.post(`/api/apikeys/revoke/${id}`, {}, {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setMessage('API key revoked successfully.');
            fetchApiKeys();
        } catch (error) {
            setMessage(`Error: ${error.response?.data?.message || 'Failed to revoke API key'}`);
        }
    };

    return (
        <div className="api-keys">
            <h3>Manage API Keys</h3>
            {message && <p className="message">{message}</p>}

            <div className="generate-api-key">
                <h4>Generate New API Key</h4>
                <select
                    value={selectedBaseStation}
                    onChange={(e) => setSelectedBaseStation(e.target.value)}
                >
                    <option value="">Select Base Station</option>
                    {baseStations.map(station => (
                        <option key={station._id} value={station._id}>
                            {station.base_id}
                        </option>
                    ))}
                </select>
                <button onClick={handleGenerate}>Generate API Key</button>
            </div>

            <h4>Existing API Keys</h4>
            <table className="api-keys-table">
                <thead>
                    <tr>
                        <th>API Key</th>
                        <th>Base Station</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {apiKeys.map(key => (
                        <tr key={key._id}>
                            <td>{key.key}</td>
                            <td>{key.baseStation?.base_id || 'N/A'}</td>
                            <td>{new Date(key.createdAt).toLocaleString()}</td>
                            <td>{key.revoked ? 'Revoked' : 'Active'}</td>
                            <td>
                                {!key.revoked && (
                                    <button onClick={() => handleRevoke(key._id)}>Revoke</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ApiKeys;