import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        token: localStorage.getItem('token'),
        user: null,
    });

    useEffect(() => {
        if (auth.token) {
            axios
                .get('/api/users/me', {
                    headers: { Authorization: `Bearer ${auth.token}` },
                })
                .then((res) => setAuth((prev) => ({ ...prev, user: res.data })))
                .catch(() => setAuth({ token: null, user: null }));
        }
    }, [auth.token]);

    const login = (token) => {
        localStorage.setItem('token', token);
        setAuth({ token, user: null });
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuth({ token: null, user: null });
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};