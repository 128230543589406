import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import AppRoutes from './Routes';
import { initializeAnalytics } from './utils/analytics';
import { CartProvider } from './contexts/CartContext';

function App() {
  useEffect(() => {
    // Initialize enhanced analytics tracking
    initializeAnalytics();
  }, []);

  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <Layout>
          <AppRoutes />
          </Layout>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
