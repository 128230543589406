import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/Characters.css';

const Characters = () => {
  const { auth } = useContext(AuthContext);
  const [characters, setCharacters] = useState([]);
  const [users, setUsers] = useState([]);
  const [baseStations, setBaseStations] = useState([]);
  const [formData, setFormData] = useState({
    encryptedCharacterType: '',
    serialNumber: ''
  });
  
  const [assignData, setAssignData] = useState({
    serialNumber: '',  
    userId: ''
  });

  const [visitData, setVisitData] = useState({
    serialNumber: '', 
    baseStationId: ''
  });
  const [selectedVisits, setSelectedVisits] = useState(null);
  const [message, setMessage] = useState('');
  const [characterTypes, setCharacterTypes] = useState([]);
  const [characterTypeData, setCharacterTypeData] = useState({
    characterEncryptedType: '',
    characterType: ''
  });

  useEffect(() => {
    fetchCharacters();
    fetchUsers();
    fetchBaseStations();
    fetchCharacterTypes();
  }, []);

  const fetchCharacters = async () => {
    try {
      const response = await axios.get('/api/characters/all', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCharacters(response.data);
    } catch (error) {
      console.error('Error fetching characters:', error);
      setMessage('Failed to fetch characters.');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/users', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage('Failed to fetch users.');
    }
  };

  const fetchBaseStations = async () => {
    try {
      const response = await axios.get('/api/basestations', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setBaseStations(response.data);
    } catch (error) {
      console.error('Error fetching base stations:', error);
      setMessage('Failed to fetch base stations.');
    }
  };

  const fetchCharacterTypes = async () => {
    try {
      const response = await axios.get('/api/characterTypes', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCharacterTypes(response.data);
    } catch (error) {
      console.error('Error fetching character types:', error);
      setMessage('Failed to fetch character types.');
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/characters/createAsAdmin', formData, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setMessage(`Character "${response.data.characterType}" with Serial Number "${response.data.serialNumber}" created successfully.`);
      setFormData({ encryptedCharacterType: '', serialNumber: '' });
      fetchCharacters();
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || 'Failed to create character'}`);
    }
  };

  const handleAssign = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/characters/addToUser', assignData, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setMessage('Character assigned to user successfully.');
      setAssignData({ serialNumber: '', userId: '' });
      fetchCharacters();
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || 'Failed to assign character'}`);
    }
  };

  const handleAddVisit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/api/characters/visit/${visitData.baseStationId}`,
        { serialNumber: visitData.serialNumber },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setMessage('Visit added successfully.');
      setVisitData({ serialNumber: '', baseStationId: '' });
      setSelectedVisits(null);
      fetchCharacters();
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || 'Failed to add visit'}`);
    }
  };

  const handleViewVisits = async (characterId) => {
    try {
      const response = await axios.get(`/api/characters/${characterId}/visits`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setSelectedVisits({
        characterId,
        visits: response.data
      });
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || 'Failed to fetch visits'}`);
    }
  };

  const closeVisits = () => {
    setSelectedVisits(null);
  };

  const handleAddCharacterType = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/characterTypes/appendtype', characterTypeData, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setMessage(`Character type "${response.data.characterType}" added successfully.`);
      setCharacterTypeData({ characterEncryptedType: '', characterType: '' });
      fetchCharacterTypes();
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || 'Failed to add character type'}`);
    }
  };

  return (
    <div className="admin-characters">
      <h3>Manage Characters</h3>
      {message && <p className="admin-message">{message}</p>}

      {/* Create Character Form */}
      <form onSubmit={handleCreate} className="admin-create-form">
        <h4>Create New Character</h4>
        <div>
          <label htmlFor="encryptedCharacterType">Character Type:</label>
          <select
            id="encryptedCharacterType"
            name="encryptedCharacterType"
            value={formData.encryptedCharacterType}
            onChange={(e) => setFormData({ ...formData, encryptedCharacterType: e.target.value })}
            required
          >
            <option value="">Select Character Type</option>
            {characterTypes.map(type => (
              <option key={type._id} value={type.characterEncryptedType}>
                {type.characterType} ({type.characterEncryptedType})
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="serialNumber">Serial Number:</label>
          <input
            type="text"
            id="serialNumber"
            name="serialNumber"
            value={formData.serialNumber}
            onChange={(e) => setFormData({ ...formData, serialNumber: e.target.value })}
            required
          />
        </div>
        <button type="submit">Create Character</button>
      </form>

      {/* **Updated Assign Character to User Form** */}
      <form onSubmit={handleAssign} className="admin-assign-form">
        <h4>Assign Character to User</h4>
        <div>
          <label htmlFor="serialNumber">Character Serial Number:</label>
          <select
            id="serialNumber"
            name="serialNumber"
            value={assignData.serialNumber}
            onChange={(e) => setAssignData({ ...assignData, serialNumber: e.target.value })}
            required
          >
            <option value="">Select Character</option>
            {characters
              .filter(character => !character.owner) // Only show unassigned characters
              .map(character => (
                <option 
                  key={character._id} 
                  value={character.serialNumber}
                >
                  {character.serialNumber} - {character.characterType}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label htmlFor="userId">User:</label>
          <select
            id="userId"
            name="userId"
            value={assignData.userId}
            onChange={(e) => setAssignData({ ...assignData, userId: e.target.value })}
            required
          >
            <option value="">Select User</option>
            {users.map(user => (
              <option key={user._id} value={user._id}>
                {user.displayName} ({user.email})
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Assign Character</button>
      </form>

      {/* Add Visit Form */}
      <form onSubmit={handleAddVisit} className="admin-add-visit-form">
        <h4>Add Visit to Character</h4>
        <div>
          <label htmlFor="serialNumber">Character Serial Number:</label>
          <select
            id="serialNumber"
            name="serialNumber"
            value={visitData.serialNumber}
            onChange={(e) => setVisitData({ ...visitData, serialNumber: e.target.value })}
            required
          >
            <option value="">Select Character</option>
            {characters.map(character => (
              <option key={character._id} value={character.serialNumber}>
                {character.serialNumber} - {character.characterType}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="baseStationId">Base Station:</label>
          <select
            id="baseStationId"
            name="baseStationId"
            value={visitData.baseStationId}
            onChange={(e) => setVisitData({ ...visitData, baseStationId: e.target.value })}
            required
          >
            <option value="">Select Base Station</option>
            {baseStations.map(station => (
              <option key={station._id} value={station._id}>
                {station.base_id}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Add Visit</button>
      </form>

      {/* Create Character Type Form */}
      <form onSubmit={handleAddCharacterType} className="admin-create-form">
        <h4>Create New Character Type</h4>
        <div>
          <label htmlFor="characterEncryptedType">Encrypted Type:</label>
          <input
            type="text"
            id="characterEncryptedType"
            name="characterEncryptedType"
            value={characterTypeData.characterEncryptedType}
            onChange={(e) => setCharacterTypeData({ ...characterTypeData, characterEncryptedType: e.target.value })}
            required
          />
        </div>
        <div>
          <label htmlFor="characterType">Type:</label>
          <input
            type="text"
            id="characterType"
            name="characterType"
            value={characterTypeData.characterType}
            onChange={(e) => setCharacterTypeData({ ...characterTypeData, characterType: e.target.value })}
            required
          />
        </div>
        <button type="submit">Add Character Type</button>
      </form>

      {/* Character Types Table */}
      <h4>Existing Character Types</h4>
      <table className="admin-characters-table">
        <thead>
          <tr>
            <th>Encrypted Type</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {characterTypes.map(type => (
            <tr key={type._id}>
              <td>{type.characterEncryptedType}</td>
              <td>{type.characterType}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Characters Table */}
      <h4>Existing Characters</h4>
      <table className="admin-characters-table">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Type</th>
            <th>Owner</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {characters.map(character => (
            <tr key={character._id}>
              <td>{character.serialNumber}</td>
              <td>{character.characterType}</td>
              <td>{character.owner?.displayName || 'Unassigned'} [{character.owner?._id || 'N/A'}]</td>
              <td>{new Date(character.createdAt).toLocaleString()}</td>
              <td>
                <button onClick={() => handleViewVisits(character._id)}>View Visits</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Visits Modal */}
      {selectedVisits && (
        <div className="admin-modal-overlay">
          <div className="admin-modal">
            <h4>Visits for {characters.find(c => c._id === selectedVisits.characterId)?.characterType}</h4>
            <button className="admin-close-button" onClick={closeVisits}>X</button>
            <table className="visits-table">
              <thead>
                <tr>
                  <th>Base Station</th>
                  <th>Visit Date</th>
                </tr>
              </thead>
              <tbody>
                {selectedVisits.visits.map(visit => (
                  <tr key={visit._id}>
                    <td>{visit.baseStation?.base_id || 'N/A'}</td>
                    <td>{new Date(visit.visitDate).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default Characters;
