import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/Layout.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`layout ${isHomePage ? 'no-background' : ''}`}>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/">Woodland Stories</Link>
        </div>
        <div className="navbar-hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/shop" onClick={toggleMenu}>Shop</Link></li>
          <li><Link to="/map" onClick={toggleMenu}>Map</Link></li>
        </ul>
      </nav>
      <div className="content">
        {children}
      </div>
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; Woodland Stories Co. {new Date().getFullYear()}</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
