import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/AdminInterests.css';

const Interests = () => {
    const { auth } = useContext(AuthContext);
    const [interests, setInterests] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchInterests();
    }, []);

    const fetchInterests = async () => {
        try {
            const response = await axios.get('/api/interest/interests', {
                headers: { Authorization: `Bearer ${auth.token}` },
            });
            setInterests(response.data);
        } catch (error) {
            console.error('Error fetching interests:', error);
            setMessage('Failed to fetch interests.');
        }
    };

    return (
        <div className="admin-interests">
            <h3>Registered Interests</h3>
            {message && <p className="message">{message}</p>}
            {interests.length === 0 ? (
                <p>No interests registered yet.</p>
            ) : (
                <table className="interests-table">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Registered At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {interests.map(interest => (
                            <tr key={interest._id}>
                                <td>{interest.email}</td>
                                <td>{new Date(interest.registeredAt).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Interests;