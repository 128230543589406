import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import MapComponent from '../components/MapComponent';
import useAnalytics from '../hooks/useAnalytics';
import '../styles/MapPage.css';

const MapPage = () => {
    const [markers, setMarkers] = useState([]);
    const { auth } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [interestStatus, setInterestStatus] = useState('');
    
    // Initialize analytics
    const analytics = useAnalytics('MapPage');

    useEffect(() => {
        const fetchBaseStations = async () => {
            try {
                const response = await axios.get('/api/basestations', {
                    headers: { Authorization: `Bearer ${auth.token}` }
                });
                const baseStations = response.data;
                const newMarkers = baseStations.map(station => ({
                    lat: station.latitude,
                    lng: station.longitude,
                    tag: `${station.friendly_name}`
                }));
                setMarkers(newMarkers);
                
                // Track successful base stations load
                analytics.trackEvent('base_stations_loaded', {
                    count: baseStations.length,
                    status: 'success'
                });
            } catch (error) {
                console.error('Error fetching base stations:', error);
                analytics.trackError(error, 'MapPage-fetchBaseStations');
            }
        };

        if (auth.token) {
            fetchBaseStations();
        }
    }, [auth.token]);

    const handleInterestSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/interest/register-interest', { email });
            setInterestStatus('success');
            setEmail('');
            
            // Track successful interest registration
            analytics.trackEvent('interest_registration', {
                status: 'success',
                source: 'map_page'
            });
        } catch (error) {
            console.error('Error registering interest:', error);
            analytics.trackError(error, 'MapPage-interestRegistration');
            setInterestStatus('error');
        }
    };

    // Handler for map interactions
    const handleMapInteraction = (type, details) => {
        analytics.trackInteraction('map_interaction', `map-${type}`, {
            type,
            ...details
        });
    };

    return (
        <div className="map-page">
            {/* Register Interest Section */}
            <section className="register-interest-section">
                <h2>We are expanding to new locations, which means more adventures!</h2>
                <p>Register your interest to join our mailing list and stay updated.</p>
                {interestStatus === 'success' && <p className="success-message">Successfully registered!</p>}
                {interestStatus === 'error' && <p className="error-message">Registration failed. Please try again.</p>}
                <form onSubmit={handleInterestSubmit} className="register-interest-form">
                    <input
                        type="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Register</button>
                </form>
            </section>

            <h1 className="map-title">Map</h1>
            <div className="map-content">
                <h2 className="map-subtitle">Our Locations</h2>
                <div className="map-container">
                    <MapComponent 
                        markers={markers} 
                        center={[52.3555, -1.1743]} 
                        zoom={6}
                        onMarkerClick={(marker) => {
                            handleMapInteraction('marker_click', {
                                markerId: marker.tag,
                                location: `${marker.lat},${marker.lng}`
                            });
                        }}
                        onZoomChange={(zoom) => {
                            handleMapInteraction('zoom_change', { zoom });
                        }}
                        onDragEnd={(center) => {
                            handleMapInteraction('drag', {
                                center: `${center.lat},${center.lng}`
                            });
                        }}
                    />
                </div>
                <div className="location-counts">
                    <div className="location-count">
                        <h3>{markers.length}</h3>
                        <p>Base Stations</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapPage;
