import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ShopPage.css';
import ProductCard from '../components/ProductCardComponent';
import ProductModal from '../components/ProductModalComponent';
import SkeletonProductCard from '../components/SkeletonProductCard';
import useAnalytics from '../hooks/useAnalytics';
import Cart from '../components/Cart'; // Import Cart component

const ShopPage = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    treeStumpLocation: '',
    shortDescription: ''
  });
  const [formSubmitStatus, setFormSubmitStatus] = useState(null);
  
  // Initialize analytics
  const analytics = useAnalytics('ShopPage');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get('/api/stripe/products');
        setProducts(res.data);
        analytics.trackEvent('products_loaded', {
          productCount: res.data.length
        });
      } catch (error) {
        console.error('Error fetching products:', error);
        analytics.trackError(error, 'ShopPage-fetchProducts');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleBuy = async (priceId) => {
    try {
      const res = await axios.post('/api/stripe/create-checkout-session', { items: [{ price: priceId, quantity: 1 }] });
      analytics.trackEvent('checkout_initiated', {
        priceId,
        status: 'success'
      });
      window.location.href = res.data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      analytics.trackError(error, 'ShopPage-checkout');
      alert('Failed to initiate purchase.');
    }
  };

  const handleCardClick = (product) => {
    analytics.trackInteraction('product_view', `product-${product.id}`, {
      productId: product.id,
      productName: product.name,
      productPrice: product.price
    });
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (selectedProduct) {
      analytics.trackInteraction('close_product_modal', `product-${selectedProduct.id}`);
    }
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('/api/contact', formData);
      setFormSubmitStatus('success');
      analytics.trackEvent('stump_form_submit', {
        status: 'success',
        location: formData.treeStumpLocation
      });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        treeStumpLocation: '',
        shortDescription: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      analytics.trackError(error, 'ShopPage-formSubmit');
      setFormSubmitStatus('error');
    }
    setLoading(false);
  };

  // Example locations for autocomplete
  const locations = [
    'North Garden',
    'East Orchard',
    'South Lawn',
    'West Vineyard'
    // Add more locations as needed
  ];

  return (
    <div className="shop-page">
      <Cart /> 
      <div className="shop-content">
        <div className="shop-header">
          <h1>Shop</h1>
          <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
            We are currently expanding to new locations. Please check back soon!
          </p>
        </div>
        <hr />
        <div className="shop-character-heading">
          <h2>Characters</h2>
        </div>
        <div className="character-grid">
          {loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <SkeletonProductCard key={index} />
            ))
          ) : (
            products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                handleBuy={handleBuy}
                onCardClick={() => handleCardClick(product)}
              />
            ))
          )}
        </div>

        <div className="shop-stump-heading">
          <h2>Order a Woodland Stories Tree Stump for your Garden</h2>
        </div>
        <div className="stump-form-container">
          <p>If you have a private garden that you would like to include the woodland stories experience, please contact us using the form below and we will be in touch with more details on how to get setup.</p>
          {formSubmitStatus === 'success' ? (
            <p className="success-message">Successfully sent</p>
          ) : (
            <>
              {formSubmitStatus === 'error' && <p className="error-message">An error occurred. Please try again.</p>}
              <form onSubmit={handleSubmit} className="stump-order-form">
                <div className="form-row">
                  <input type="text" name="firstName" placeholder="First Name" required value={formData.firstName} onChange={handleInputChange} />
                  <input type="text" name="lastName" placeholder="Last Name" required value={formData.lastName} onChange={handleInputChange} />
                </div>
                <input type="email" name="email" placeholder="Email" required value={formData.email} onChange={handleInputChange} />
                <input
                  type="text"
                  name="treeStumpLocation"
                  placeholder="Tree Stump Location"
                  list="locations"
                  required
                  value={formData.treeStumpLocation}
                  onChange={handleInputChange}
                />
                <datalist id="locations">
                  {locations.map((location, index) => (
                    <option key={index} value={location} />
                  ))}
                </datalist>
                <textarea
                  name="shortDescription"
                  placeholder="Please provide a short description of why you are interested in including woodland stories in your garden"
                  required
                  value={formData.shortDescription}
                  onChange={handleInputChange}
                ></textarea>
                <button type="submit" className="submit-button">Submit</button>
              </form>
            </>
          )}
        </div>
      </div>
      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ShopPage;
