import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

const PrivateRoute = ({ children, adminOnly = false }) => {
   const { auth } = useContext(AuthContext);
   const location = useLocation();
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
     if (auth.token && !auth.user) {
       const checkUser = setInterval(() => {
         if (auth.user) {
           clearInterval(checkUser);
           setIsLoading(false);
         }
       }, 100);
     } else {
       setIsLoading(false);
     }
   }, [auth]);

   if (isLoading) {
     return <div>Loading...</div>;
   }

   if (!auth.token) {
     return <Navigate to="/login" state={{ from: location }} replace />;
   }

   if (adminOnly && auth.user.role !== 'admin') {
     return <Navigate to="/" replace />;
   }

   return children;
};

export default PrivateRoute;